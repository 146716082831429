@import url(https://use.typekit.net/ars0buc.css);
body {
  background: #111;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: 'effra', sans-serif;
}

.app {
  width: 100vw;
  height: 100vh;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
}
